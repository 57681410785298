import React, {Component} from 'react';
import Layout from '../components/layout';
import {graphql, Link} from "gatsby";
// import {decodeXML} from 'entities';
import PromoFooter from "../components/promo-footer-banner";
import {htmlDecode, mapStickyBanner, mapResourcesList, mapGartner} from '../common/utils';
import ResourceDownload from '../components/get-resource';
import '../assets/styles/pages/thank-you-page.scss';


// const Download = ({link}) => !link ? null :
//     <>
//         <a className={'cato-button resources-green-button download-link'} href={link} target={'_blank'}>DOWNLOAD</a>
//         <img style={{marginTop: '25px'}} src={catoEbook} alt={'ebook'} />
//     </>;
// const YouTube = ({v}) => !v ? null : <iframe title="yt vid" width="560" height="315" src={`https://www.youtube.com/embed/${v}`} frameBorder="0"
//                                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
//                                  allowFullScreen/>;

class ThankYouPage extends Component {
    constructor (props) {
        super(props);
        this.title = htmlDecode(this.props.data.wpPage.title);
        if(typeof window !== 'undefined') {
            this.Ty_FileUrl = window.sessionStorage.getItem("Ty_FileUrl");
            this.Ty_YTUrl = window.sessionStorage.getItem("Ty_YTUrl");

            window.sessionStorage.removeItem("Ty_YTUrl");
            window.sessionStorage.removeItem("Ty_FileUrl");

        }
    }

    genBreadcrumbs = () => (
            <div className="bread-crumbs">
                <Link to="/">Home</Link>
                <span> > </span>
                <span>{this.title}</span>
            </div>
        );

    render() {
        const {location} = this.props;
        const {data} = this.props;
        const {wpPage} = data;
        let {content, resourcesBannerRows} = wpPage;

        // const resourceLink = this.Ty_FileUrl ? this.Ty_FileUrl : this.Ty_YTUrl ? this.Ty_YTUrl : null;

        const resource = location.state && location.state.assetValue ?
        {
            link: location.state.assetValue,
            type: location.state.assetType
        }
        : null;

        const stickyBanner = mapStickyBanner(wpPage)

        const resourcesData = resourcesBannerRows.resourcesList ? mapResourcesList(resourcesBannerRows.resourcesList) : null;

        const gartner = mapGartner(wpPage);

        return (
            <Layout className="thank-you-page"
                    lang={this.props.pageContext.lang}
                    whiteMenu={true}
                    title={this.title}
                    gartner={gartner}
                    seoPath={this.props.location.pathname}
                    translations={this.props.pageContext.translations}
                    wpid={wpPage.databaseId}
                    {...{stickyBanner}}>
                <img height="1" width="1" style={{borderStyle:'none', position: 'absolute'}} alt=""
                     src="//insight.adsrvr.org/track/conv/?adv=04x3nkh&ct=0:1oxit3j&fmt=3"/>
                <section className={'content'}>
                    {this.genBreadcrumbs()}
                    <div className={'thank-you-container'} >
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: '20px'}}>
                            <h1 style={{width: '100%', maxWidth: '560px'}} >{this.title}</h1>
                            <div style={{width: '100%', maxWidth: '560px'}} dangerouslySetInnerHTML={{__html: content}}/>
                        </div>
                    </div>
                    {resource &&
                        <ResourceDownload
                            {...resource}
                        />
                    }
                    {/*<Download   link={this.Ty_FileUrl} />*/}
                    {/*<YouTube    v={this.Ty_YTUrl} />*/}

                    <div style={{width: '100%'}}>
                        <img src="https://trackingapi.trendemon.com/api/Events/pixel?AccountId=1644&GoalId=697" alt="" />
                        <div className="trd-ph-embedded" data-group="recommend"/>
                    </div>
                </section>

                {resourcesData &&
                <div className={'ebook-section-wrapper'}>
                    <PromoFooter className={"show-small"} data={resourcesData}/>
                </div>}
            </Layout>
        )
    }
}

export default ThankYouPage;


export const pageQuery = graphql`
    query thankYouPageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            id
            databaseId
            path: uri
            resourcesBannerRows {
                resourcesList {
                    ... on WpResource {
                        id
                        title
                        resourceFields {
                            resourceLongDescription
                            resourceSmallImage {
                                altText
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 400) {
                                            ...GatsbyImageSharpFluid_noBase64
                                        }
                                    }
                                    extension
                                    publicURL
                            }
                            }
                            resourceButtonText
                            resourceLink
                        }
                    }
                }
            }
            ...StickyBanner
            ...GartnerStrip
        }
    }
`